/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Checkbox, FormControl, FormLabel, FormControlLabel, FormGroup } from '@material-ui/core';

import useSiteImages from '../../hooks/use-site-images'

import Layout from '../../components/layout'
import Content from '../../components/Content'
import Wrapper from '../../components/Wrapper'
import Hero from '../../components/Hero'
import SEO from '../../components/SEO'
import Disqus from '../../components/Disqus'

const DefinitionList = props => {
    const { children, showBook, showDictionary, showOnline } = props
    var definitions = children.filter(
        definition =>
            (definition.props.book && showBook) ||
            (definition.props.online && showOnline) ||
            (definition.props.dictionary && showDictionary))
    definitions.sort((a, b) => a.props.date.localeCompare(b.props.date))
    return (
        <ul>
            {definitions}
        </ul>
    )
}

const Definition = props => {
    const { source, author, edition, date, url } = props
    return (
        <div style={{
            border: "1px solid black",
            paddingTop: "2pt",
            paddingLeft: "2pt",
            paddingRight: "2pt",
            paddingBottom: "2pt",
            margin: "2pt"
        }}>
        <b>{source}
        {author ? (" by " + author): ""}
        {edition ? (", " + edition + " edition") : ""}
        {" "} ({url ? <a href={url}>{date}</a> : date})</b><br/>
        {props.children}
        </div>
    )
}

const Page = props => {
    const slug = "definitions-of-racism"
    const location = "/" + slug
    const title = "Definitions of Racism"
    const description = title

    const [state, setState] = React.useState({
        showBook: true,
        showDictionary: true,
        showOnline: true
    })

    const handleCheckbox = (event) => {
        setState({ ...state,
            [event.target.name]: event.target.checked,
            revision: state.revision + 1
        })
    }

    const definitions =
        <DefinitionList
                showBook={state.showBook}
                showDictionary={state.showDictionary}
                showOnline={state.showOnline}>
            <Definition book={true}
                source="How to be an Antiracist" date="2019" author="Ibram X. Kendi">
                Racism is a marriage of policies and ideas that produces and
                normalizes racial inequities.
            </Definition>

            <Definition dictionary={true} online={true}
                source="merriam-webster.com" date="2021-01-06"
                url="https://www.merriam-webster.com/dictionary/racism">
                rac·ism | \ ˈrā-ˌsi-zəm also -ˌshi- \<br />
          1 : a belief that race is a fundamental determinant of human traits and capacities and that racial differences produce an inherent superiority of a particular race<br />
          2 a : the systemic oppression of a racial group to the social, economic, and political advantage of another<br />
      specifically : WHITE SUPREMACY sense 2<br />
      b : a political or social system founded on racism and designed to execute its principles
            </Definition>

            <Definition dictionary={true}
                    source="The Merriam-Webster Dictionary" date="2019">
                <b>rac·ism</b> \'rā-ˌsi-zəm\ n 1 : a belief that some races are by
                nature superior to others; <i>also</i> : discrimation based on such
                belief - <b>rac·ist</b> \-sist\ <i>n</i>
            </Definition>

            <Definition dictionary={true}
                    source="Oxford English Dictionary" edition="second" date="1989">
                a. The theory that distinctive human characteristics and
                abilities are determined by race. b. = racialism.
            </Definition>

            <Definition dictionary={true}
                    source="Oxford English Dictionary" edition="third" date="2008">
                Prejudice, antagonism, or discrimination by an individual,
                institution, or society, against a person or people on the
                basis of their nationality or (now usually) their membership
                of a particular racial or ethnic group, typically one that is
                a minority or marginalized. Also: beliefs that members of a
                particular racial or ethnic group possess innate
                characteristics or qualities, or that some racial or ethnic
                groups are superior to others; an ideology based on such
                beliefs. Cf. racialism n.
            </Definition>

            <Definition dictionary={true}
                    source="Webster's New Collegiate Dictionary" edition="seventh" date="1971">
                rac-ism \'rā-,siz-əm\ n 1 : a belief that race is the primary
                determinant of human traits and capacities and that racial
                differences produce an inherent superiority of a particular
                race 2 : RACIALISM 1 - rac-ist \-səst n
            </Definition>

            <Definition dictionary={true}
                    source="Webster's New Collegiate Dictionary" edition="eighth" date="1973">
                rac-ism \'rā-,siz-əm also -shiz-\ n 1 a belief that race is
                the primary determinant of human traits and capacities and
                that racial differences produce an inherent superiority of a
                particular race 2 : RACIALISM 1 - rac-ist \-səst also -shǝst
                n
            </Definition>

            <Definition dictionary={true}
                    source="Webster's New Collegiate Dictionary" edition="ninth" date="1983">
                rac-ism \'ra-,siz-əm, -,shiz-\ n (1936) 1 : a belief that
                race is the primary determinant of human traits and
                capacities and that racial differences produce an inherent
                superiority of a particular race 2: racial prejudice or
                discrimination racist \-səst, -shəst\ n or adj
            </Definition>

            <Definition dictionary={true}
                    source="Webster's New Collegiate Dictionary" edition="tenth" date="1993">
                rac-ism \'ra-,siz-əm, also -,shiz-\ n (1936) 1 : a belief
                that race is the primary determinant of human traits and
                capacities and that racial differences produce an inherent
                superiority of a particular race 2: racial prejudice or
                discrimination - racist \-səst, -shəst\ n or adj
            </Definition>

            <Definition dictionary={true}
                    source="Webster's New Collegiate Dictionary" edition="eleventh" date="2005">
                rac-ism \'ra-,siz-əm, also -,shiz-\ n (1933) 1 : a belief
                that race is the primary determinant of human traits and
                capacities and that racial differences produce an inherent
                superiority of a particular race 2: racial prejudice or
                discrimination - racist \-səst, -shəst\ n or adj
            </Definition>

            <Definition book={true}
                    source="White Fragility" date="2018" author="Robin DiAngelo">
                Summarized:
                Racism is a racial group’s collective prejudice backed by the power
                of legal authority and institutional control.
            </Definition>


{/* path: 2001-10-28T16:48:11.json
  * duration: 43 days, 16:41:30 */}
<Definition online={true}
        source="Wikipedia" date="2001-10-28"
        url="https://www.wikipedia.com/w/index.php?title=Racism&oldid=334464306">
    Racism is the political or ideological application of the concept of
    race, especially in terms of racial superiority.
</Definition>

{/* path: 2003-05-02T08:34:38.json
  * duration: 125 days, 9:01:21 */}
<Definition online={true}
        source="Wikipedia" date="2003-05-02"
        url="https://www.wikipedia.com/w/index.php?title=Racism&oldid=943068">
    RACISM or RACIALISM is a form of discrimination based on race,
    especially the belief that one race is superior to another. Racism may
    be expressed individually and consciously, through explicit thoughts,
    feelings, or acts, or socially and unconsciously, through institutions
    that promote inequality between races.
</Definition>

{/* path: 2004-01-02T05:48:54.json
  * duration: 111 days, 8:40:20 */}
<Definition online={true}
        source="Wikipedia" date="2004-01-02"
        url="https://www.wikipedia.com/w/index.php?title=Racism&oldid=2068546">
    RACISM refers to beliefs, practices, and institutions that negatively
    discriminate against people based on their perceived or ascribed race.
    Sometimes the term is also used to describe the belief that race is the
    primary determinant of human capacities, or that individuals should be
    treated differently based on their ascribed race.
</Definition>

{/* path: 2005-05-28T05:10:19.json
  * duration: 60 days, 3:16:42 */}
<Definition online={true}
        source="Wikipedia" date="2005-05-28"
        url="https://www.wikipedia.com/w/index.php?title=Racism&oldid=14352810">
    RACISM has many different definitions. Historically, it has been defined
    as the belief that race is the primary determinant of human capacities,
    that a certain race is inherently superior or inferior to others, and/or
    that individuals should be treated differently according to their racial
    designation. Sometimes _racism_ means beliefs, practices, and
    institutions that discriminate against people based on their perceived
    or ascribed race. There is a growing, but somewhat controversial,
    opinion that racism is a _system_ of oppression -- a nexus of racist
    beliefs, whether explicit, tacit or unconscious; practices;
    organizations and institutions that combine to discriminate against and
    marginalize a class of people who share a common racial designation,
    based on that designation.
</Definition>

{/* path: 2007-10-18T19:55:14.json
  * duration: 64 days, 23:20:32 */}
<Definition online={true}
        source="Wikipedia" date="2007-10-18"
        url="https://www.wikipedia.com/w/index.php?title=Racism&oldid=165476903">
    RACISM has many definitions, the most common and widely accepted being
    the belief that members of one race are intrinsically superior or
    inferior to members of other races.
</Definition>

{/* path: 2008-07-31T18:12:20.json
  * duration: 152 days, 1:13:50 */}
<Definition online={true}
        source="Wikipedia" date="2008-07-31"
        url="https://www.wikipedia.com/w/index.php?title=Racism&oldid=229062379">
    RACISM, by its simplest definition, is the belief that race is the
    primary determinant of human traits and capacities and that racial
    differences produce an inherent superiority of a particular race. People
    with racist beliefs might hate certain groups of people according to
    their racial groups. In the case of institutional racism, certain racial
    groups may be denied rights or benefits, or get preferential treatment.
    Racial discrimination typically points out taxonomic differences between
    different groups of people, even though anybody can be racialised,
    independently of their somatic differences. According to the United
    Nations conventions, there is no distinction between the term RACIAL
    DISCRIMINATION and ETHNIC DISCRIMINATION.
</Definition>

{/* path: 2009-05-29T12:02:59.json
  * duration: 83 days, 0:27:51 */}
<Definition online={true}
        source="Wikipedia" date="2009-05-29"
        url="https://www.wikipedia.com/w/index.php?title=Racism&oldid=293080388">
    RACISM is the belief that race is the primary determinant of human
    traits and capacities and that racial differences produce an inherent
    superiority of a particular race.[1] In the case of institutional
    racism, certain racial groups may be denied rights or benefits, or get
    preferential treatment, while reverse racism favours members of a
    historically disadvantaged group at the expense of those of a
    historically advantaged group. Racial discrimination typically points
    out taxonomic differences between different groups of people, even
    though anybody can be racialised, independently of their somatic
    differences. According to the United Nations conventions, there is no
    distinction between the term RACIAL DISCRIMINATION and ETHNIC
    DISCRIMINATION.
</Definition>

{/* path: 2010-07-24T00:03:17.json
  * duration: 177 days, 5:52:52 */}
<Definition online={true}
        source="Wikipedia" date="2010-07-24"
        url="https://www.wikipedia.com/w/index.php?title=Racism&oldid=375125031">
    RACISM is the belief that the genetic factors which constitute race are
    a primary determinant of human traits and capacities and that racial
    differences produce an inherent superiority of a particular race.[1]
    Racism's effects are called "racial discrimination." In the case of
    institutional racism, certain racial groups may be denied rights or
    benefits, or receive preferential treatment.
</Definition>

{/* path: 2011-10-17T19:47:49.json
  * duration: 83 days, 19:38:42 */}
<Definition online={true}
        source="Wikipedia" date="2011-10-17"
        url="https://www.wikipedia.com/w/index.php?title=Racism&oldid=456060879">
    RACISM is the belief that inherent different traits in human racial
    groups justify discrimination. In the modern English language, the term
    "racism" is used predominantly as a pejorative epithet. It is applied
    especially to the practice or advocacy of racial discrimination of a
    pernicious nature (i.e. which harms particular groups of people), and
    which is often justified by recourse to racial stereotyping or
    pseudo-science.
</Definition>

{/* path: 2012-10-24T11:44:02.json
  * duration: 68 days, 21:34:59 */}
<Definition online={true}
        source="Wikipedia" date="2012-10-24"
        url="https://www.wikipedia.com/w/index.php?title=Racism&oldid=519554910">
    RACISM is usually defined as views, practices and actions reflecting the
    belief that humanity is divided into distinct biological groups called
    races and that members of a certain race share certain attributes which
    make that group as a whole less desirable, more desirable, inferior or
    superior.[1][2][3]
</Definition>

{/* path: 2014-06-01T19:44:10.json
  * duration: 214 days, 9:09:31 */}
<Definition online={true}
        source="Wikipedia" date="2014-06-01"
        url="https://www.wikipedia.com/w/index.php?title=Racism&oldid=611125558">
    RACISM consists of both prejudice and discrimination based in social
    perceptions of biological differences between peoples. It often takes
    the form of social actions, practices or beliefs, or political systems
    that consider different races to be ranked as inherently superior or
    inferior to each other, based on presumed shared inheritable traits,
    abilities, or qualities. It may also hold that members of different
    races should be treated differently.[1][2][3]
</Definition>

{/* path: 2016-04-04T03:16:52.json
  * duration: 227 days, 12:18:01 */}
<Definition online={true}
        source="Wikipedia" date="2016-04-04"
        url="https://www.wikipedia.com/w/index.php?title=Racism&oldid=713449639">
    RACISM is a product of the complex interaction in a given society of a
    race-based worldview with prejudice, stereotyping, and discrimination.
    Racism can be present in social actions, practices, or political systems
    (e.g., apartheid) that support the expression of prejudice or aversion
    in discriminatory practices. The ideology underlying racist practices
    often includes the idea that humans can be subdivided into distinct
    groups that are different in their social behavior and innate capacities
    and that can be ranked as inferior or superior.[1] Racist ideology can
    become manifest in many aspects of social life. Associated social
    actions may include xenophobia, otherness, segregation, hierarchical
    ranking, supremacism, and related social phenomena.
</Definition>

{/* path: 2017-01-02T14:30:46.json
  * duration: 249 days, 0:04:29 */}
<Definition online={true}
        source="Wikipedia" date="2017-01-02"
        url="https://www.wikipedia.com/w/index.php?title=Racism&oldid=757925416">
    RACISM is discrimination and prejudice towards people based on their
    race or ethnicity. Today, the use of the term "racism" does not easily
    fall under a single definition.[1]
</Definition>

{/* path: 2018-01-01T09:53:11.json
  * duration: 175 days, 23:34:33 */}
<Definition online={true}
        source="Wikipedia" date="2018-01-01"
        url="https://www.wikipedia.com/w/index.php?title=Racism&oldid=818069433">
    RACISM is the belief in the superiority of one race over another, which
    often results in discrimination and prejudice towards people based on
    their race or ethnicity. Today, the use of the term "racism" does not
    easily fall under a single definition.[1]
</Definition>

{/* path: 2020-03-23T11:47:47.json
  * duration: 237 days, 15:37:58 */}
<Definition online={true}
        source="Wikipedia" date="2020-03-23"
        url="https://www.wikipedia.com/w/index.php?title=Racism&oldid=946954523">
    RACISM is the belief that
    groups of humans possess different behavioral traits corresponding to
    physical appearance and can be divided based on the superiority of one
    race over another.[1][2][3] It may also mean prejudice, discrimination,
    or antagonism directed against other people because they are of a
    different race or ethnicity.[4][5] Modern variants of racism are often
    based in social perceptions of biological differences between peoples.
    These views can take the form of social actions, practices or beliefs,
    or political systems in which different races are ranked as inherently
    superior or inferior to each other, based on presumed shared inheritable
    traits, abilities, or qualities.[6][7][8]
</Definition>


{/* path: 2007-09-09T18:50:51.json
  * duration: 140 days, 21:17:07 */}
<Definition online={true}
        source="Conservapedia" date="2007-09-09"
        url="https://www.conservapedia.com/index.php?title=Racism&oldid=289407">
    RACISM is prejudice and discrimination based on race. An example is a
    claim that of inferiority or superiority based on the color of one's
    skin. Another example was the Jim Crow laws.
</Definition>

{/* path: 2012-03-27T14:18:23.json
  * duration: 260 days, 2:53:20 */}
<Definition online={true}
        source="Conservapedia" date="2012-03-27"
        url="https://www.conservapedia.com/index.php?title=Racism&oldid=970454">
    RACISM is prejudice and
    discrimination based on race. An example is a claim that of inferiority
    or superiority based on the color of one's skin. Another example was the
    Jim Crow laws; one more: Nicolas Sarkozy was accused of racism for
    ordering closure of 300 illegal gypsy camps and expulsion of Roma after
    riot. [1] Sarkozy was behind the controversial measure to deport Roma
    gypsies, breaching European immigration laws. European Union officials
    accused Sarkozy of "fanning xenophobia" in his move to deport the Roma.
    [2]
</Definition>

{/* path: 2013-03-26T19:35:11.json
  * duration: 416 days, 22:09:19 */}
<Definition online={true}
        source="Conservapedia" date="2013-03-26"
        url="https://www.conservapedia.com/index.php?title=Racism&oldid=1042922">
    RACISM is prejudice and discrimination based on race. An example is a
    claim that of inferiority or superiority based on the color of one's
    skin. Another example was the Jim Crow laws. Racism means the hatred of
    another person because of the color of his or her skin; some used the
    term _racism_ for perceived difference in origin.
</Definition>

{/* path: 2018-05-20T22:46:18.json
  * duration: 255 days, 2:44:05 */}
<Definition online={true}
        source="Conservapedia" date="2018-05-20"
        url="https://www.conservapedia.com/index.php?title=Racism&oldid=1423590">
    RACISM is prejudice and discrimination based on race. An example is a
    claim that of inferiority or superiority based on the color of one's
    skin. Other examples include the Jim Crow laws and the discrimination
    and violence against white South Africans by their country's
    government.
</Definition>

{/* path: 2019-07-31T13:07:17.json
  * duration: 176 days, 10:15:56 */}
<Definition online={true}
        source="Conservapedia" date="2019-07-31"
        url="https://www.conservapedia.com/index.php?title=Racism&oldid=1545014">
    RACISM is making a moral judgment
    about a person based on skin color or ethnic heritage. The hatred of
    another person because of the color of his or her skin is racist; some
    use the term _racism_ for the perceived difference in origin, as a
    member of an ethnic group.
</Definition>

        </DefinitionList>

    return (
        <Layout location={props.location}>
            <SEO
                title={title}
                description={description}
                path={slug}
            />

            <Hero
                title={title}
            />

            <Wrapper>
                <article>
                    <Content>
                        <p>
                        These are some definitions of racism published by
                        various sources in various years. If you have more, please share them.
                        </p>

        <p>
        <FormControl component="show">
              <FormLabel component="legend">Show</FormLabel>
        <FormGroup row={true}>
            <FormControlLabel
                control={<Checkbox checked={state.showBook} onChange={handleCheckbox} name="showBook" />}
                label="Books"
            />
            <FormControlLabel
                control={<Checkbox checked={state.showDictionary} onChange={handleCheckbox} name="showDictionary" />}
                label="Dictionaries"
            />
            <FormControlLabel
                control={<Checkbox checked={state.showOnline} onChange={handleCheckbox} name="showOnline" />}
                label="Websites"
            />
        </FormGroup>
        </FormControl>
        </p>
                        {definitions}
                    </Content>
                </article>
            </Wrapper>

            <Wrapper as="aside">
                <Disqus
                    slug={slug}
                    title={title}
                    location={props.location}
                />
            </Wrapper>

        </Layout>
    )
}

export default Page
